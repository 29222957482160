const tryPrependText = (content, prependText) => content ? `${prependText}${content}` : content;

const getSource = searchParameters => tryPrependText(searchParameters.get('gclid'), 'GCLID-');

const storeTrackingInformationInCookie = () => {
	const searchParameters = new URLSearchParams(window.location.search);
	const source = getSource(searchParameters);
	if (source) {
		document.cookie = `source=${encodeURIComponent(source)}; max-age=${60 * 60}; domain=${window.location.hostname}; SameSite=None; Secure`;
	}
};

export default {
	storeTrackingInformationInCookie,
};
