/* Set up gate form, if any */
const initPlayer = function () {
	const urlParameters = new URLSearchParams(window.location.search);
	const bypassed = Boolean(urlParameters.get('g'));

	/* Check if we're gated */
	const gate = document.querySelector('#page-video #video-gate');

	if (gate && !bypassed) {
		/* Hide video, display gate form */
		document.querySelector('#page-video #video-player')?.remove();
		document.querySelector('#page-video #video-meta')?.remove();

		/* Set gate form params dynamically */
		document.querySelector('#page-video #video-gate form')?.removeAttribute('target');
	} else {
		document.querySelector('#page-video #video-gate')?.classList.add('is-hidden');
		document.querySelector('#page-video #video-player')?.classList.remove('is-hidden');
		document.querySelector('#page-video #video-meta')?.classList.remove('is-hidden');
	}
};

export default {
	initPlayer,
};
