import moment from 'moment';
import Parallax from 'parallax-js';
import Vivus from 'vivus';
import inlineSVG from 'Vendor/inlineSvg';

import whenVisible from 'Utils/whenVisible.js';
import eachElement from 'Utils/eachElement.js';
import intercom from 'Scripts/intercom.js';
import analytics from 'Scripts/analytics.js';
import video from 'Scripts/video.js';
import carousel from 'Scripts/carousel.js';
import calendly from 'Scripts/calendly.js';
import forms from 'Scripts/forms.js';
import Tabs from 'Scripts/tabs.js';
import Modal from 'Scripts/modal.js';
import trackingCookieHelper from 'Scripts/trackingInformationCookieHelper.js';
import webinars from 'Scripts/webinars.js';
import dropInDemo from 'Scripts/drop-in-demo.js';

import 'lightbox2';

document.addEventListener('DOMContentLoaded', () => {
	/* Load SVGs inline, so we can colour them */
	inlineSVG.init({
		svgSelector: 'img.svg',
		initClass: 'js-inlinesvg',
	}, () => {
		document.documentElement.classList.add('is-loaded');

		const deployHero = document.querySelector('#deploy-hero-background');
		const homeHero = document.querySelector('#homepage-hero');

		if (window.innerWidth > 1023) {
			if (window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
				if (deployHero) {
					new Vivus('deploy-hero-background', {
						type: 'scenario',
						duration: 280,
						/* eslint-disable-next-line no-mixed-operators */
						pathTimingFunction: x => x < 0.5 ? 4 * x * x * x : 1 - (-2 * x + 2) ** 3 / 2,
						onReady: myVivus => {
							myVivus.el.style.visibility = 'inherit';
						},
					}).play();
				}

				if (homeHero) {
					new Vivus('homepage-hero', {
						type: 'delayed',
						delay: 140,
						duration: 360,
						/* eslint-disable-next-line no-mixed-operators */
						pathTimingFunction: x => x < 0.5 ? 4 * x * x * x : 1 - (-2 * x + 2) ** 3 / 2,
						onReady: myVivus => {
							myVivus.el.classList.add('is-active');
						},
					}).play();
				}
			}
		} else {
			if (deployHero) {
				deployHero.style.visibility = 'inherit';
			}

			if (homeHero) {
				homeHero.classList.add('is-active');
			}
		}
	});

	intercom.setUpAcquire();
	analytics.trackTaggedAnchors();
	carousel.setupReviewCarousel();
	forms.setUpFormResponse();
	calendly.setUpCalendly();
	trackingCookieHelper.storeTrackingInformationInCookie();
	webinars.setUpWebinarRegistration();
	dropInDemo.setUpDropInDemoRegistration();
	Modal.setUpModals();

	// Listen for scrolls and trigger menu background
	window.addEventListener('scroll', () => {
		document.documentElement.classList.toggle('is-scrolled', window.scrollY > 0);
	});
	window.dispatchEvent(new Event('scroll'));

	// Apply class when element is visible
	whenVisible();

	// Apply parallaxes
	if (window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
		eachElement('.parallax', element => {
			new Parallax(element);
		});
	}

	// Backup card time
	const backupCard = document.querySelector('#backup-card');

	if (backupCard) {
		document.querySelector('#backup-card-time').innerHTML = moment()
			.add(1, 'day')
			.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
			.format('dddd, MMMM Do YYYY, h:mm a');
	}

	// Video player
	const videoPlayer = document.querySelector('#page-video #video-player');

	if (videoPlayer) {
		video.initPlayer();
	}

	// Set up tabs
	eachElement('.tabs', instance => {
		new Tabs(instance);
	});

	// CTA count
	if (document.documentElement.id === 'page-post') {
		/* Get CTAs within blog post content area */
		const contentArea = document.querySelector('main article.content');
		const ctas = contentArea.querySelectorAll('[analytics-track-event="blog-post-cta"]');

		/* Push to dataLayer */
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			ctaCount: ctas.length,
		});
	}

	// Front page carousel
	const homepageCarousel = document.querySelector('.hero .title ul');
	if (document.documentElement.id === 'page-index' && homepageCarousel) {
		const items = homepageCarousel.querySelectorAll('li');

		setInterval(() => {
			const currentItem = homepageCarousel.querySelector('.is-active');
			const nextItem = currentItem.nextElementSibling || items[0];
			const previousItem = currentItem.previousElementSibling || items[items.length - 1];

			currentItem.className = 'is-away';
			nextItem.className = 'is-active';
			previousItem.className = '';
		}, 2000);
	}
});
